import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/index.css';
import mxmlogWhiteLogo from '../images/white-logo.svg';
import CustonButton from "../components/CustonButton";
import CustonInput from "../components/CustonInput";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (!localStorage.getItem('data')) {
      localStorage.clear();
      navigate("/login");
    } else {
      navigate("/");
    }
  }, [navigate]);

  function handleChange({ target: { id, value } }) {
    setFormValue((previusState) => {
      return {
        ...previusState,
        [id]: value,
      }
    });
  }

  async function login() {
    setLoading(true);
    const body = {
      email: formValue.email,
      password: formValue.password,
    }
    
    const init = {
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(body),
    };
    
    const response = await fetch('http://api.mxmlog.com.br/user/login', init);
    const data = await response.json();

    if (!data?.token) {
      return setLoading(false);
    }

    localStorage.setItem('data', JSON.stringify(data));
    setLoading(false);
    navigate("/");
  }

  function makeAlert() {
    alert('Função ainda não desenvolvida');
  }

  return (
    <div className="w-100 h-100 flex-r overflow-hidden">
      <div className="bg-orange w-50 h-100 flex-ac flex-jc">
        <img className='max-500' src={ mxmlogWhiteLogo } alt="" />
      </div>
      <div className='bg-light-gray flex-ac flex-c flex-jc w-50 h-100'>
        <div className='login-content-container'>
          <h1 className='txt-black fs-22 weight-700 mb-1'>Login</h1>
          <span className='txt-light-gray fs-16 weight-300 mb-3'>Coloque seu e-mail e senha para entrar</span>
          <CustonInput autofocus={ true } key='email' funcOnChange={ handleChange } value={ formValue.email } marginBottom='1rem' id="email" label="E-mail" />
          <CustonInput key='password' funcOnChange={ handleChange } value={ formValue.password } marginBottom='1rem' type='password' id="password" label="Senha" />
          <div className='w-100 flex-je mb-3'>
            <span className='txt-light-orange fs-14 weight-300 pointer' onClick={ makeAlert }>Esqueci minha senha</span>
          </div>
          <CustonButton funcOnClick={ login } marginBottom='1rem' width='100%' backgroundColor='#FF5000' text='Entrar' loading={ loading } loadingColor='#FFBB00' loadingSize={ 30 } />
          <span className='txt-light-gray weight-300 fs-14'>Ainda não tem uma conta? <span className='txt-light-orange weight-600 pointer' onClick={ makeAlert }>Solicite agora.</span></span>
        </div>
      </div>
    </div>
  );
}

export default Login;
