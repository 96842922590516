import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Config from "./Config";
import Dashboard from "./Dashboard";
import Driver from "./Driver";
import GasStation from "./GasStation";
import Integration from "./Integration";
import Map from "./Map";
import NavBar from "./NavBar";
import Relatory from "./Relatory";
import Route from "./Route";
import Support from "./Support";
import User from "./User";
import Vehicle from "./Vehicle";
import grayBell from '../images/bell-gray-icon.svg';
import grayCamera from '../images/camera-gray-icon.svg';
import arrowDownBlack from '../images/arrow-down-black-icon.svg';

function Home() {
  const navigate = useNavigate();

  const components = {
    '/': <Dashboard />,
    '/relatories': <Relatory />,
    '/vehicles': <Vehicle />,
    '/routes': <Route />,
    '/drivers': <Driver />,
    '/gasstation': <GasStation />,
    '/integrations': <Integration />,
    '/users': <User />,
    '/config': <Config />,
    '/support': <Support />,
    '/map': <Map />,
  };

  function naoDesenvolvido() {
    alert('Função ainda não desenvolvida');
  }

  useEffect(() => {
    if (!localStorage.getItem('data')) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="w-100 flex-jc flex-r bg-light-gray">
      <NavBar />
      <div className="flex-ac flex-c w-100">
      <header className="header">
        <img onClick={ naoDesenvolvido } className="pointer" src={ grayBell } alt="" />
        <div className="flex-ac pointer" onClick={ naoDesenvolvido }>
          <div className="perfil-image m-icon-header">
            <img className={ JSON.parse(localStorage.getItem('data'))?.image ? 'w-100' : '' } src={ JSON.parse(localStorage.getItem('data'))?.image || grayCamera } alt="" />
          </div>
          <div className="flex-c">
            <span className="fs-14 txt-black weight-500">{ JSON.parse(localStorage.getItem('data'))?.companyName }</span>
            <span className="fs-14 txt-gray weight-300">{ JSON.parse(localStorage.getItem('data'))?.email }</span>
          </div>
          <img className="ml-1" src={ arrowDownBlack } alt="" />
        </div>
      </header>
      {components[window.location.pathname]}
      </div>
    </div>
  );
}

export default Home;
