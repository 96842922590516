function NotFound() {
  return (
    <div className="w-100 h-100 flex-ac flex-jc flex-c bg-light-gray">
      <h1 className="txt-orange fs-64 weight-900">404</h1>
      <h1 className="txt-orange fs-64 weight-900">not found</h1>
    </div>
  );
}

export default NotFound;
