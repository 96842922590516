import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import NotFound from './screens/NotFound';
import Login from './screens/Login';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={ <Home /> } />
      <Route exact path="/login" element={ <Login /> } />
      <Route exact path="/relatories" element={ <Home /> } />
      <Route exact path="/vehicles" element={ <Home /> } />
      <Route exact path="/routes" element={ <Home /> } />
      <Route exact path="/drivers" element={ <Home /> } />
      <Route exact path="/gasstation" element={ <Home /> } />
      <Route exact path="/integrations" element={ <Home /> } />
      <Route exact path="/users" element={ <Home /> } />
      <Route exact path="/config" element={ <Home /> } />
      <Route exact path="/support" element={ <Home /> } />
      <Route exact path="/map" element={ <Home /> } />
      <Route path="*" element={ <NotFound /> } />
      {/*
        <Route exact path="/teste" element={ <Teste /> } />
        <Route path="/users/:id" element={Users} />
        <Route path="/users" render={(props ) => <Users {...props} greetingMessage="Good morning" />} />
        <Route path="/strict-access/:userLog" element={StrictAccess} />
        <Route path="/strict-access/" render={(props) => <StrictAccess {...props} user={this.user} />} />
        <Route path="/about" element={About} />
      */}
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
