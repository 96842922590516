import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const CustonTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FF5000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FF5000',
  },
  '& .MuiOutlinedInput-root': {
    color: '#7B809A',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '1rem',
    '& fieldset': {
      borderColor: '#C7CCD0',
    },
    '&:hover fieldset': {
      borderColor: '#585757',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FF5000',
    },
  },
});

function CustonInput({ type = 'text', id, label, funcOnChange, value, autofocus = false }) {
  return (
    <CustonTextField autoFocus={ autofocus } name={ id } value={ value } fullWidth size='small' onChange={ funcOnChange } type={ type } id={ id } label={ label } />
  );
}

export default CustonInput;
