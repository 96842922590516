import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustonButton from "../components/CustonButton";
import CustonInput from "../components/CustonInput";
import CustonTable from "../components/CustonTable";

function Vehicle() {
  const [vehicles, setVehicles] = useState([]);
  const [searchField, setSearchField] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const token = JSON.parse(localStorage.getItem('data'))?.token;

      const init = {
        method: 'GET',
        headers: { 'Content-type': 'application/json; charset=UTF-8', token },
      };

      const response = await fetch('http://api.mxmlog.com.br/truck/all', init);
      const data = await response.json();

      if (!data?.error) {
        return setVehicles(data);
      }

      if (data?.error === "jwt expired") {
        localStorage.clear();
        return navigate("/login");
      }
    })()
  }, [navigate]);

  function makeAlert() {
    alert('Função ainda não desenvolvida');
  }

  function handleChange({ target: { value } }) {
    setSearchField(value);
  }

  const columns = [
    {
      width: 50,
      label: '',
      dataKey: 'image',
    },
    {
      width: 200,
      label: 'Marca',
      dataKey: 'brand',
    },
    {
      width: 120,
      label: 'Modelo',
      dataKey: 'model',
    },
    {
      width: 120,
      label: 'Placa',
      dataKey: 'plate',
    },
    {
      width: 100,
      label: 'Frota',
      dataKey: 'frota',
    },
    {
      width: 120,
      label: 'Rastreador',
      dataKey: 'rastreador',
    },
    {
      width: 100,
      label: 'Status',
      dataKey: 'active',
    },
    {
      width: 80,
      label: '',
      dataKey: 'action',
    },
  ];

  return(
    <div className="w-100 bg-light-gray">
      <div className="content-container">
        <h1 className="txt-black fs-32 weight-500 mb-2">Veículos</h1>
          <div className='search-container'>
            <CustonInput key='search' funcOnChange={ handleChange } value={ searchField } id="search" label="Pesquisar veículo" />
            <CustonButton funcOnClick={ makeAlert } width='100%' backgroundColor='#FF5000' text='Cadastrar veículo' />
          </div>
          <CustonTable columns={ columns } rows={ vehicles } deleteFunction={ makeAlert } editFunction={ makeAlert } />
      </div>
    </div>
  );
}

export default Vehicle;
