import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import CustonButton from '../components/CustonButton';
import mxmLogo from '../images/orange-logo.svg';
import dashboardGrayIcon from '../images/dashboard-gray-icon.svg';
import dashboardOrangeIcon from '../images/dashboard-orange-icon.svg';
import relatoryGrayIcon from '../images/relatory-gray-icon.svg';
import relatoryOrangeIcon from '../images/relatory-orange-icon.svg';
import truckGrayIcon from '../images/truck-gray-icon.svg';
import truckOrangeIcon from '../images/truck-orange-icon.svg';
import routeGrayIcon from '../images/route-gray-icon.svg';
import routeOrangeIcon from '../images/route-orange-icon.svg';
import driverGrayIcon from '../images/driver-gray-icon.svg';
import driverOrangeIcon from '../images/driver-orange-icon.svg';
import gasStationGrayIcon from '../images/gas-station-gray-icon.svg';
import gasStationOrangeIcon from '../images/gas-station-orange-icon.svg';
import supportGrayIcon from '../images/support-gray-icon.svg';
import supportOrangeIcon from '../images/support-orange-icon.svg';
import integrationGrayIcon from '../images/integration-gray-icon.svg';
import integrationOrangeIcon from '../images/integration-orange-icon.svg';
import usersGrayIcon from '../images/users-gray-icon.svg';
import usersOrangeIcon from '../images/users-orange-icon.svg';
import configGrayIcon from '../images/config-gray-icon.svg';
import configOrangeIcon from '../images/config-orange-icon.svg';
import mapWhiteIcon from '../images/map-white-icon.svg';

function NavBar() {
  const navigate = useNavigate();
  const [url, setUrl] = useState(window.location.pathname);

  function handleClick(url) {
    setUrl(url);

    if (url === '/map') {
      navigate('/map');
    }
  }

  const menu = [
    {
      name: 'Dashboard',
      url: '/',
      icon: dashboardGrayIcon,
      selectedIcon: dashboardOrangeIcon,
    },
    {
      name: 'Relatórios',
      url: '/relatories',
      icon: relatoryGrayIcon,
      selectedIcon: relatoryOrangeIcon,
    },
    {
      name: 'Veículos',
      url: '/vehicles',
      icon: truckGrayIcon,
      selectedIcon: truckOrangeIcon,
    },
    {
      name: 'Rotas',
      url: '/routes',
      icon: routeGrayIcon,
      selectedIcon: routeOrangeIcon,
    },
    {
      name: 'Motoristas',
      url: '/drivers',
      icon: driverGrayIcon,
      selectedIcon: driverOrangeIcon,
    },
    {
      name: 'Postos',
      url: '/gasstation',
      icon: gasStationGrayIcon,
      selectedIcon: gasStationOrangeIcon,
    },
    {
      name: 'Suporte',
      url: '/support',
      icon: supportGrayIcon,
      selectedIcon: supportOrangeIcon,
    },
  ];

  const general = [
    {
      name: 'Integrações',
      url: '/integrations',
      icon: integrationGrayIcon,
      selectedIcon: integrationOrangeIcon,
    },
    {
      name: 'Usuários',
      url: '/users',
      icon: usersGrayIcon,
      selectedIcon: usersOrangeIcon,
    },
    {
      name: 'Configurações',
      url: '/config',
      icon: configGrayIcon,
      selectedIcon: configOrangeIcon,
    },
  ];

  return (
    <div className="navbar">
      <img className='mb-2' src={ mxmLogo } alt="" />
      <div className='container-itens-navbar'>
        <div className='w-100 mb-05'>
          <span className='txt-light-gray weight-200 fs-12 ml-2'>MENU</span>
        </div>
        <div className='navbar-itens'>
          {menu.map((item, index) => (
            <NavLink onClick={ () => handleClick(item.url) } className='navbar-item' aria-selected={ item.url === url } key={ index + item.url } to={ item.url }>
              <img src={ item.url === url ? item.selectedIcon : item.icon } alt="" />
              <span>{ item.name }</span>
            </NavLink>
          ))}
        </div>
        <div className='w-100 mb-05'>
          <span className='txt-light-gray weight-200 fs-12 ml-2'>GERAL</span>
        </div>
        <div className='navbar-itens'>
          {general.map((item, index) => (
            <NavLink onClick={ () => handleClick(item.url) } className='navbar-item' aria-selected={ item.url === url } key={ index + item.url } to={ item.url }>
              <img src={ item.url === url ? item.selectedIcon : item.icon } alt="" />
              <span>{ item.name }</span>
            </NavLink>
          ))}
        </div>
        <CustonButton classes='flex-c flex-jc flex-ac' icon={ mapWhiteIcon } width='210px' height='110px' text='Mapa' borderRadius='20px ' funcOnClick={ () => handleClick('/map') } />
      </div>
    </div>
  );
}

export default NavBar;
