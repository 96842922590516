import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { styled } from '@mui/material/styles';
import editIcon from '../images/pen-gray-icon.svg';
import deleteIcon from '../images/trash-gray-icon.svg';
import grayCamera from '../images/camera-gray-icon.svg';
import greenDot from '../images/dot-green-icon.svg';
import redX from '../images/x-red-icon.svg';
import grayWpp from '../images/whatsapp-gray-icon.svg';
import grayCopy from '../images/copy-gray-icon.svg';

function CustonTable({ columns, rows, editFunction, deleteFunction, shareFunction, copyFunction }) {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFFFFF',
      color: '#FF5000',
      borderColor: '#EBEFF2',
      paddingLeft: '10px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#707683',
      borderColor: '#EBEFF2',
      padding: '10px',
    }
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td': {
      border: 0,
    },
  }));

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <StyledTableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
      <StyledTableRow>
        {columns && columns.map((column) => (
          <StyledTableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? 'right' : 'left'}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  }

  function makeButtons() {
    return (
      <div className="flex-ac gap-10">
        <img className="pointer" src={ deleteIcon } onClick={ deleteFunction } alt="" />
        <img className="pointer" src={ editIcon } onClick={ editFunction } alt="" />
      </div>
    );
  }

  function makeImage(image) {
    return (
      <div className='perfil-image'>
        <img className={ image ? 'w-100' : '' } src={ image || grayCamera } alt="" />
      </div >
    );
  }

  function makeStatus(status) {
    return (
      <React.Fragment>
        <div className={ status === '1' ? 'status-active' : 'status-inative' }>
          <img src={ status === '1' ? greenDot : redX } alt=""/>
          <span>{ status === '1' ? 'Ativo' : 'Inativo'}</span>
        </div>
      </React.Fragment>
    );
  }

  function makeFullName(row) {
    return (
      <React.Fragment>
        {`${row.firstname} ${ row.lastname}`}
      </React.Fragment>
    );
  }

  function makeShareButtons() {
    return (
      <div className="flex-ac gap-10">
        <div className='flex-ac gap-2 fw-300 pointer' onClick={ copyFunction }>
          <img src={ grayCopy } alt="" />
          <span className='fs-12'>Copiar link</span>
        </div>
        <div className='flex-ac gap-2 fw-300 pointer' onClick={ shareFunction }>
          <img src={ grayWpp } alt="" />
          <span className='fs-12'>WhatsApp</span>
        </div>
      </div>
    );
  }

  function formatPhone(phone = '') {
    return (
      <React.Fragment>
        {phone.length === 11 ? `(${phone.substring(0,2)}) ${phone.substring(2,7)}-${phone.substring(7,11)}` : phone }
      </React.Fragment>
    );
  }

  function returnCorrectValue(dataKey, content, fullRow) {
    if (dataKey === 'image') {
      return makeImage(content);
    }

    if (dataKey === 'action') {
      return makeButtons();
    }

    if (dataKey === 'active') {
      return makeStatus(content);
    }

    if (dataKey === 'firstname') {
      return makeFullName(fullRow);
    }

    if (dataKey === 'cellphone') {
      return formatPhone(content);
    }

    if (dataKey === 'roleid') {
      const roles = {
        1: 'Administrador',
        2: 'Gerente',
        3: 'Usuário',
      }

      return roles[content];
    }

    if (dataKey === 'share') {
      return makeShareButtons();
    }

    return content;
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns && columns.map((column) => (
          <StyledTableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
          >
            {returnCorrectValue(column.dataKey, row[column.dataKey], row)}
          </StyledTableCell>
        ))}
      </React.Fragment>
    );
  }

  return (
    <Paper style={{ height: 500, width: '100%' }}>
      <TableVirtuoso
        data={ rows }
        components={ VirtuosoTableComponents }
        fixedHeaderContent={ fixedHeaderContent }
        itemContent={ rowContent }
      />
    </Paper>
  );
}

export default CustonTable;
