import { Button, styled } from "@mui/material";
import Loading from "./Loading";


function CustonButton({ marginBottom = '0', width = '100%', classes = '', height = '40px', borderRadius = '8px', backgroundColor = '#FF5000', hoverColor = '#D64300', variant = 'contained', text = '', funcOnClick, loading = false, loadingColor = '', loadingSize = '', icon = false }) {
  const BtnCuston = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(backgroundColor),
    backgroundColor,
    height,
    width,
    borderRadius,
    marginBottom,
    '&:hover': {
      backgroundColor: hoverColor,
    },
  }));

  return (
    <BtnCuston className={ classes } startIcon={ icon && <img src={ icon } alt="" /> } variant={ variant } onClick={ funcOnClick } disabled={ loading }>{ loading ? <Loading size={ loadingSize } color={ loadingColor } /> : text }</BtnCuston>
  );
}

export default CustonButton;
