
function Support() {
  return(
    <div className="w-100 bg-light-gray">
      <div className="content-container">
        <h1 className="txt-black fs-32 weight-500 mb-2">Suporte</h1>
        <div className="main-content">
          
        </div>
      </div>
    </div>
  );
}

export default Support;
