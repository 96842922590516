import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

function Loading({ size, color }) {
  return (
    <Stack sx={{ color }} spacing={2} direction="row"><CircularProgress size={ size } color="inherit" /></Stack>
  );
}

export default Loading;
